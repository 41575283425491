<template>
    <div class="q-answer">

        <ul v-if="answerObj">
            <li v-for="answer in answerOptions" :key="answer.option.id">
                <label class="form-check-label">
                    <input type="radio" class="form-check-input" v-model="selectOption" :value="answer"> {{answer.option.option}}
                </label>
                <div class="option-image" v-if="selectOption && answer.option.id == selectOption.option.id &&  answer.link">
                    <img :src="answer.link" />
                    <button class="btn btn-primary" @click="addPhoto()" >Zmień</button>
                </div>
                <pv-textarea
                        v-if="selectOption && answer.option.id == selectOption.option.id && answer.option.extended"
                        class="form-control"
                        style="max-width: 400px;"
                        :autoResize="true"
                        rows="5"
                        cols="30"
                        v-model="answer.extendedDescription"/>
            </li>
        </ul>
    </div>
</template>

<script>

    import TextArea from 'primevue/textarea';
    import { useFileDialog } from '@vueuse/core'

    export default {
        props: ['answer'],
        emits: ['changeValue'],

        data() {
            return {
                answerOptions: [],
                selectOption: null,
                answerObj: null,
                createdData: false
            }
        },


        components: {
            'pv-textarea': TextArea,
        },
        created() {
            this.answerObj = this.answer;
            this.answerOptions = this.answerObj.question.options
                .map(option => {
                    return {
                        option: option,
                        extendedDescription: null,
                        link: null
                    }
                });
            if (this.answerObj.options != null && this.answerObj.options.length > 0) {
                this.selectOption = this.answerOptions.find(a => a.option.id == this.answerObj.options[0].option.id)
                this.selectOption.id = this.answerObj.options[0].id;
                this.selectOption.extendedDescription = this.answerObj.options[0].extendedDescription;
                this.selectOption.link = this.answerObj.options[0].link;
            }
        },

        methods: {
            changeSelectedValue()   {
                this.answerObj.options = [this.selectOption];
            },

            async addPhoto() {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });


                const { open, onChange} = useFileDialog();
                open();
                onChange(async (files) => {
                    if (files.length > 0) {
                        let image_data_url = await toBase64(files[0]);
                        let base64result = image_data_url.substr(image_data_url.indexOf(',') + 1);
                        let response = await this.$store.dispatch('ba/upload', {data: base64result, extension: 'jpg', filename: 'photo.jpg'});
                        this.selectOption.link = response.url;
                    }
                });
            }
        },

        watch: {
            selectOption: {
                handler() {
                    if (this.selectOption) {
                        if (this.selectOption.option.photo && !this.selectOption.link) {
                            this.addPhoto();
                        }
                    }
                    this.changeSelectedValue();
                },
                deep: true
            },
            answerObj: {
                handler() {
                    if (this.createdData) {
                        this.$emit('changeValue', this.answerObj);
                    } else {
                        this.createdData = true;
                    }
                },
                deep: true
            }
        }
    }
</script>