<template>
    <Breadcrumbs :list="breadcrumpsList"/>
    <div class="main-card mb-3 card">
        <div class="card-body">
            <div class="app-form">
                <div class="row poll-form mb-2">
                    <div class="col-sm-3 col-md-2">
                        <Menu :model="items" />
                    </div>
                    <div class="col-sm-9 col-md-10">
                        <div v-if="activePage == 'setting'">
                            <div class="poll-card">
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label">Kolor tła (RGB)</label>
                                    <div class="col-sm-4">
                                        <pv-input-text class="form-control" placeholder="Kolor tła" v-model="settings.background"/>
                                    </div>
                                    <div class="col-sm-4">
                                        <pv-color-picture v-model="settings.background" inline format="hex" />
                                    </div>
                                </div>
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label">Czcionka</label>
                                    <div class="col-sm-4">
                                        <pv-dropdown v-model="settings.fontName" :options="fonts" placeholder="Wybierz czcionkę" class="form-control" />
                                    </div>
                                </div>
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label">Rozmiar czcionki</label>
                                    <div class="col-sm-4">
                                        <pv-inputnumber v-model="settings.fontSize" inputId="integeronly"  style="width: 100%" />
                                    </div>
                                </div>
                               <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label">Kolor czcionki (RGB)</label>
                                    <div class="col-sm-4">
                                        <pv-input-text class="form-control" placeholder="Kolor czcionki" v-model="settings.fontColor"/>
                                    </div>
                                    <div class="col-sm-4">
                                        <pv-color-picture v-model="settings.fontColor" inline format="hex" />
                                    </div>
                                </div>

                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label">Podgląd</label>
                                    <div class="col-sm-10" :style="{'background-color': '#'+settings.background, 'font-size': settings.fontSize + 'px',  color: '#'+settings.fontColor, 'font-family': settings.fontName}" style="padding: 15px">
                                        <p>1. Dzień dobry, czy chcesz wziąść udział w ankiecie?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activePage == 'start'">
                            <div class="poll-card">
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label" for="startPollTitle">Tytuł</label>
                                    <div class="col-sm-10">
                                        <pv-input-text id="startPollTitle" class="form-control" placeholder="Tytuł" v-model="fullPoll.pollDto.startPage.title"/>
                                    </div>
                                </div>
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label" for="startPollDesc">Opis</label>
                                    <div class="col-sm-10">
                                        <pv-textarea id="startPollDesc" class="form-control" :autoResize="true" rows="5" cols="30" v-model="fullPoll.pollDto.startPage.description"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activePage == 'end'">
                            <div class="poll-card">
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label" for="endPollTitle">Tytuł</label>
                                    <div class="col-sm-10">
                                        <pv-input-text id="endPollTitle" class="form-control" placeholder="Tytuł" v-model="fullPoll.pollDto.endPage.title"/>
                                    </div>
                                </div>
                                <div class="position-relative row mb-3">
                                    <label class="form-label col-sm-2 col-form-label" for="endPollDesc">Opis</label>
                                    <div class="col-sm-10">
                                        <pv-textarea id="endPollDesc" class="form-control" :autoResize="true" rows="5" cols="30" v-model="fullPoll.pollDto.endPage.description"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="activePage == 'page'">
                            <div class="buttons mb-2">
                                <div class="button-questions">
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('SINGLE')" >
                                            <img src="@/assets/images/poll/radio.png" />
                                        </button>
                                        <span>Jednokrotnego wyboru</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('MULTIPLE')" >
                                            <img src="@/assets/images/poll/check.png" />
                                        </button>
                                        <span>Wielokrotnego wyboru</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('TEXT')" >
                                            <img src="@/assets/images/poll/text.png" />
                                        </button>
                                        <span>Odpowiedź tekstowa</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('EMAIL')" >
                                            <img src="@/assets/images/poll/email.png" />
                                        </button>
                                        <span>Odpowiedź email</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('NUMBER')" >
                                            <img src="@/assets/images/poll/numeric.png" />
                                        </button>
                                        <span>Odpowiedź liczbowa</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('STAR')" >
                                            <img src="@/assets/images/poll/stars.png" />
                                        </button>
                                        <span>Ocena</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('ORDER')" >
                                            <img src="@/assets/images/poll/order.png" />
                                        </button>
                                        <span>Uporządkowanie</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('PROMOTION')" >
                                            <img src="@/assets/images/poll/engineering.png" />
                                        </button>
                                        <span>Mechanizmy</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('GIFT')" >
                                            <img src="@/assets/images/poll/gift.png" />
                                        </button>
                                        <span>Gadżety</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('REGISTER')" >
                                            <img src="@/assets/images/poll/writing.png" />
                                        </button>
                                        <span>Rejestracja</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question btn-poll-verify" @click="addQuestion('VERIFY_REGISTER')" >
                                            <img src="@/assets/images/poll/writing.png" />
                                        </button>
                                        <span>Weryfikacja</span>
                                    </div>
                                    <div class="button-question">
                                        <button class="mb-2 mr-2 btn-icon btn-icon-only btn-shadow btn-outline-2x btn btn-outline-primary btn-poll-question" @click="addQuestion('IMAGE')" >
                                            <img src="@/assets/images/poll/image.png" />
                                        </button>
                                        <span>Zdjęcie</span>
                                    </div>
                                </div>
                                <div class="button-remove-page">
                                    <button class="mb-2 mr-2 btn-icon btn-icon-only btn btn-danger btn-poll-question" @click="deletePage" >
                                        <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                    </button>
                                    <span>Usuń stronę</span>
                                </div>
                            </div>
                            <div class="poll-card">
                                <pv-order-list v-model="currentQuestions" listStyle="height:auto; max-height: none;" dataKey="number" class="poll-order-list">
                                    <template #item="slotProps">
                                        <div class="list-flex">
                                            <SingleView v-if="slotProps.item.questionType == 'SINGLE'" :question="slotProps.item" style="width: 100%" />
                                            <MultiView v-if="slotProps.item.questionType == 'MULTIPLE'" :question="slotProps.item" style="width: 100%" />
                                            <TextView v-if="slotProps.item.questionType == 'TEXT'" :question="slotProps.item" style="width: 100%" />
                                            <EmailView v-if="slotProps.item.questionType == 'EMAIL'" :question="slotProps.item" style="width: 100%" />
                                            <NumberView v-if="slotProps.item.questionType == 'NUMBER'" :question="slotProps.item" style="width: 100%" />
                                            <StarView v-if="slotProps.item.questionType == 'STAR'" :question="slotProps.item" style="width: 100%" />
                                            <GiftView v-if="slotProps.item.questionType == 'GIFT'" :question="slotProps.item" style="width: 100%" />
                                            <RegisterView v-if="slotProps.item.questionType == 'REGISTER'" :question="slotProps.item" style="width: 100%" />
                                            <VerifyView v-if="slotProps.item.questionType == 'VERIFY_REGISTER'" :question="slotProps.item" style="width: 100%" />
                                            <OrderView v-if="slotProps.item.questionType == 'ORDER'" :question="slotProps.item" style="width: 100%" />
                                            <PromotionView v-if="slotProps.item.questionType == 'PROMOTION'" :question="slotProps.item" style="width: 100%" />
                                            <ImageView v-if="slotProps.item.questionType == 'IMAGE'" :question="slotProps.item" style="width: 100%" />
                                            <div class="list-buttons" >
                                                <button v-if="checkUpArrow(slotProps.item)" class="mb-2 mr-2 btn-icon btn-icon-only btn btn-secondary btn-poll-question" @click="upQuestion(slotProps.item)" >
                                                    <i class="fa fa-arrow-up btn-icon-wrapper"> </i>
                                                </button>
                                                <button v-if="checkDownArrow(slotProps.item)" class="mb-2 mr-2 btn-icon btn-icon-only btn btn-secondary btn-poll-question" @click="downQuestion(slotProps.item)" >
                                                    <i class="fa fa-arrow-down btn-icon-wrapper"> </i>
                                                </button>
                                                <button class="mb-2 mr-2 btn-icon btn-icon-only btn btn-warning btn-poll-question" @click="editQuestion(slotProps.item)" >
                                                    <i class="pe-7s-note btn-icon-wrapper"> </i>
                                                </button>
                                                <button class="mb-2 mr-2 btn-icon btn-icon-only btn btn-danger btn-poll-question" @click="deleteQuestion(slotProps.item)" >
                                                    <i class="pe-7s-trash btn-icon-wrapper"> </i>
                                                </button>
                                            </div>
                                        </div>
                                    </template>
                                </pv-order-list>
                            </div>
                        </div>

                    </div>
                </div>
                <button class="btn btn-primary pull-right" @click="submit($event)" >Zapisz</button>
                <button class="btn btn-outline-primary pull-right me-2" @click="cancel($event)" >Anuluj</button>
            </div>
        </div>
        <single-question :visibleDialog="visibleDialog == 'SINGLE'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <multi-question :visibleDialog="visibleDialog == 'MULTIPLE'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <text-question :visibleDialog="visibleDialog == 'TEXT'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <email-question :visibleDialog="visibleDialog == 'EMAIL'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <number-question :visibleDialog="visibleDialog == 'NUMBER'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <star-question :visibleDialog="visibleDialog == 'STAR'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <order-question :visibleDialog="visibleDialog == 'ORDER'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <gift-question :visibleDialog="visibleDialog == 'GIFT'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <register-question :visibleDialog="visibleDialog == 'REGISTER'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <verify-question :visibleDialog="visibleDialog == 'VERIFY_REGISTER'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <promotion-question :visibleDialog="visibleDialog == 'PROMOTION'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
        <image-question :visibleDialog="visibleDialog == 'IMAGE'" :dataValue="questionData" :dependentQuestions="currentDependentQuestions" @closeForm="visibleDialog = false" @saveForm="addQuestionToList" />
    </div>

</template>


<script>
    import Breadcrumbs from '@miro-labs/vue3-architect-layout/layout/base/Breadcrumbs.vue'
    import Menu from 'primevue/menu'
    import InputText from 'primevue/inputtext';
    import OrderList from 'primevue/orderlist';
    import TextArea from 'primevue/textarea';
    import Dropdown from 'primevue/dropdown';
    import ColorPicker from 'primevue/colorpicker';
    import InputNumber from 'primevue/inputnumber';
    import Message from '@miro-labs/vue3-message/Message'
    import SingleQuestion from "./dialog/SingleQuestion.vue"
    import MultiQuestion from "./dialog/MultiQuestion.vue"
    import TextQuestion from "./dialog/TextQuestion.vue"
    import EmailQuestion from "./dialog/EmailQuestion.vue"
    import NumberQuestion from "./dialog/NumberQuestion.vue"
    import StarQuestion from "./dialog/StarQuestion.vue"
    import OrderQuestion from "./dialog/OrderQuestion.vue"
    import GiftQuestion from "./dialog/GiftQuestion.vue"
    import RegisterQuestion from "./dialog/RegisterQuestion.vue"
    import PromotionQuestion from "./dialog/PromotionQuestion.vue"
    import ImageQuestion from "./dialog/ImageQuestion.vue"
    import VerifyQuestion from "./dialog/VerifyQuestion.vue"


    import GiftView from "./view/GiftView.vue"
    import MultiView from "./view/MultiView.vue"
    import NumberView from "./view/NumberView.vue"
    import OrderView from "./view/OrderView.vue"
    import RegisterView from "./view/RegisterView.vue"
    import SingleView from "./view/SingleView.vue"
    import StarView from "./view/StarView.vue"
    import TextView from "./view/TextView.vue"
    import EmailView from "./view/EmailView.vue"
    import PromotionView from "./view/PromotionView.vue"
    import ImageView from "./view/ImageView.vue"
    import VerifyView from "./view/VerifyView.vue"

    export default {
        setup() {
            const breadcrumpsList = [
                {label: "Strona główna", routerName: 'mainPage', params: {}, query: {}},
                {label: "ITPL", routerName: 'shopList', params: {}, query: {}},
                {label: "Akcje", routerName: 'shopActionList', params: {}, query: {}},
                {label: "Ankieta"}
            ];
            return {
                breadcrumpsList

            }
        },

        components: {
            Breadcrumbs,
            Menu,
            'pv-input-text': InputText,
            'pv-textarea': TextArea,
            'pv-order-list': OrderList,
            'pv-color-picture': ColorPicker,
            'pv-dropdown': Dropdown,
            'pv-inputnumber': InputNumber,
            SingleQuestion,
            MultiQuestion,
            TextQuestion,
            EmailQuestion,
            NumberQuestion,
            StarQuestion,
            OrderQuestion,
            GiftQuestion,
            RegisterQuestion,
            VerifyQuestion,
            PromotionQuestion,
            ImageQuestion,
            GiftView,
            MultiView,
            NumberView,
            OrderView,
            RegisterView,
            SingleView,
            StarView,
            TextView,
            EmailView,
            PromotionView,
            ImageView,
            VerifyView
        },

        data() {
            return {
                fullPoll: {
                    pollDto: {
                        name: '',
                        startPage: {title: '', description: ''},
                        endPage: {title: '', description: ''},
                        pages: [],
                        settings: []
                    },
                    questions: []
                },
                items: [],
                activePage: 'start',
                visibleDialog: false,
                currentPage: null,
                currentQuestions: [],
                questionData: null,
                currentDependentQuestions: [],
                settings: {
                    background: '',
                    fontColor: '',
                    fontSize: '',
                    fontName: ''
                },
                fonts: ['Dancing', 'Allegra', 'Lato', 'Montserrat', 'OpenSans', 'Oswald', 'Poppins', 'Raleway', 'Roboto']
            }
        },

        methods: {
            showSetting() {
                this.selectActive('setting');
                this.activePage = 'setting';
                this.currentPage = null;
            },

            showStartPage() {
                this.selectActive('start');
                this.activePage = 'start';
                this.currentPage = null;
            },

            showEndPage() {
                this.selectActive('end');
                this.activePage = 'end';
                this.currentPage = null;
            },

            showPage(ev) {
                this.selectActive(ev.item.key);
                this.activePage = 'page';
                this.currentPage = this.fullPoll.pollDto.pages.find(p => p.number == ev.item.key);
                this.refreshCurrentQuestions()
            },

            refreshCurrentQuestions() {
              this.currentQuestions = this.currentPage ?  this.fullPoll.questions
                  .filter(q => q.pollPage != null && !q.deleted && q.pollPage.number == this.currentPage.number) : [];
            },

            addPage() {
                let page = {
                    number: this.fullPoll.pollDto.pages.length + 1,
                    deleted: false
                };
                this.fullPoll.pollDto.pages.push(page);
                this.refreshMenu();
                this.selectActive(page.number);
                this.currentPage = page;
            },

            refreshMenu() {
                let items = [];
                items.push({key: 'start', label: 'Strona początkowa', icon: 'fa fa-home', command: this.showStartPage, class:''});
                this.fullPoll.pollDto.pages.filter(p => !p.deleted).forEach(page => items.push(
                    {key: page.number, label: 'Strona ' + page.number, icon: 'fa fa-file', command: this.showPage, class:''}));

                items.push({key: 'added', label: 'Dodaj stronę', icon: 'fa fa-plus', command: this.addPage, class:''});
                items.push({key: 'end', label: 'Strona końcowa', icon: 'fa fa-flag-checkered', command: this.showEndPage, class:''});
                items.push({key: 'setting', label: 'Ustawienia', icon: 'fa fa-cogs', command: this.showSetting, class:''});
                this.items = items;
            },

            selectActive(key) {
                this.items.forEach(item => item.class = item.key === key ? 'menu-active' : '');
            },

            async submit(event) {
                event.preventDefault();
                this.fullPoll.questions.forEach(q => {
                   if (!q.dependentQuestion || !q.dependentQuestion.id)  {
                       q.dependentQuestion = null;
                       q.dependentOptions = [];
                   }
                });

                //save settings
                for(let setting in  this.settings) {
                    let sett = this.fullPoll.pollDto.settings.find(s => s.name == setting);
                    if (sett) {
                        sett.value = this.settings[setting];
                    }
                }
                await this.$store.dispatch('shop/savePoll', this.fullPoll);
                this.$router.replace({name: 'shopActionList'});
            },

            cancel(event) {
                event.preventDefault();
                this.$router.replace({name: 'shopActionList'});
            },

            addQuestion(type) {
                this.currentDependentQuestions = this.fullPoll.questions
                    .filter(q => q.id > 0 && (q.questionType == 'PROMOTION' || q.questionType == 'SINGLE' || q.questionType == 'MULTIPLE')
                        && q.pollPage.number <= this.currentPage.number);
                this.questionData = {
                    pollPage: {...this.currentPage},
                    questionType: type,
                    deleted: false,
                    dependentQuestion: null,
                    dependentOptions: [],
                    number: null,
                    externalLink: '',
                    title: type == 'GIFT' ? 'Wydane gadżety' : (type == 'PROMOTION' ? 'Wybierz mechanizm' : ''),
                    description: '',
                    options: [],
                    minValue: 0,
                    maxValue: 10,
                    maxLength: 800,
                    maxOptions: null,
                    required: true
                };
                console.log(this.questionData);
                this.visibleDialog = type;
            },

            editQuestion(question) {
                this.currentDependentQuestions = this.fullPoll.questions
                    .filter(q => q.id > 0 && (q.questionType == 'PROMOTION' || q.questionType == 'SINGLE' || q.questionType == 'MULTIPLE')
                        && q.number < question.number);
                this.currentDependentQuestions.unshift({title: "Wybierz", id: null});

                this.questionData = question;
                this.visibleDialog = question.questionType;
            },


            addQuestionToList(question) {
                if (!question.dependentQuestion) {
                    question.dependentQuestion = null;
                }


                if (!question.number) {
                    this.fullPoll.questions.push(question);
                    this.rebulidNumbers();
                } else {
                    this.fullPoll.questions = this.fullPoll.questions
                        .filter(q => q.pollPage != null && !(q.number == question.number && q.pollPage.number == question.pollPage.number));
                    this.fullPoll.questions.push(question);
                    this.fullPoll.questions.sort((a, b) => a.number - b.number);
                }
                this.refreshCurrentQuestions()
            },

            rebulidNumbers() {
                let activePages = this.fullPoll.pollDto.pages.filter(p => !p.deleted);
                let deletedPages = this.fullPoll.pollDto.pages.filter(p => p.deleted);
                this.fullPoll.questions
                    .filter(q => q.pollPage == null || deletedPages.find(p => p.number == q.pollPage.number) != null)
                    .forEach(d => d.deleted = true);


                //ustalenie nr stron
                activePages.forEach(p => {
                    let newNumber = activePages.indexOf(p) +1;
                    let pollQuestions = this.fullPoll.questions
                        .filter(q => q.pollPage != null && q.pollPage.number == p.number);
                    pollQuestions.forEach(q => q.pollPage.number = newNumber);
                    p.number = newNumber;
                });
                let activeQuestions = this.fullPoll.questions.filter(q => !q.deleted);

                //ustalenie nr pytan
                activePages.forEach(p => {
                    let pollQuestions = activeQuestions
                        .filter(q => q.pollPage != null && q.pollPage.number == p.number);
                    let pollQuestionsPrev = activeQuestions
                        .filter(q => q.pollPage != null && q.pollPage.number < p.number);
                    pollQuestions.forEach(q => q.number = pollQuestionsPrev.length + pollQuestions.indexOf(q) + 1);
                });
                //aktualizacja nr pytań zależnych
                activeQuestions.forEach(q => {
                    if(q.dependentQuestion) {
                        q.dependentQuestion = activeQuestions.find(fq => fq.id == q.dependentQuestion.id);
                    }
                })
            },

            deletePage() {
                if (this.currentPage) {
                    if (this.currentPage.id) {
                        this.currentPage.deleted = 1;
                    } else {
                        this.fullPoll.pollDto.pages = this.fullPoll.pollDto.pages
                            .filter(p => p.number != this.currentPage.number);
                    }
                    this.rebulidNumbers();
                    this.refreshMenu();
                    this.showStartPage();
                }
            },

            checkDownArrow(question) {
                let maxNumber = Math.max(...this.currentQuestions.map(q => q.number));
                return question.number < maxNumber;
            },


            checkUpArrow(question) {
                let minNumber = Math.min(...this.currentQuestions.map(q => q.number));
                return question.number > minNumber;
            },

            upQuestion(question) {
                if(question.dependentQuestion) {
                    if (question.dependentQuestion.number >= question.number -1) {
                        this.$store.dispatch("message/show", [Message.WARNING, '', 'Uwaga', 'Pytanie zależne musi mieć większy numer od pytania od którego zależy']);
                        return;
                    }
                }

                let number = question.number;
                let q = this.currentQuestions.find(q => q.number == number -1);
                q.number = number;
                question.number = number -1;
                this.fullPoll.questions.sort((a, b) => a.number - b.number);
                this.rebulidNumbers();
                this.refreshCurrentQuestions()
            },

            downQuestion(question) {
                let number = question.number;
                let q = this.currentQuestions.find(q => q.number == number +1);
                if(q.dependentQuestion) {
                    if (q.dependentQuestion.number >= number) {
                        this.$store.dispatch("message/show", [Message.WARNING, '', 'Uwaga', 'Pytanie zależne musi mieć większy numer od pytania od którego zależy']);
                        return;
                    }
                }




                q.number = number;
                question.number = number + 1;
                this.fullPoll.questions.sort((a, b) => a.number - b.number);
                this.rebulidNumbers();
                this.refreshCurrentQuestions()
            },



            deleteQuestion(question) {
                if (question.id) {
                    question.deleted = true;
                } else {
                    this.fullPoll.questions = this.fullPoll.questions.filter(q => q.number != question.number);
                }
                this.rebulidNumbers();
                this.fullPoll.questions.sort((a, b) => a.number - b.number);
                this.refreshCurrentQuestions()
            }


        },



        async beforeMount() {
            this.fullPoll = await this.$store.dispatch('shop/findPoll', this.$route.params.id);
            this.fullPoll.questions.sort((a, b) => a.number - b.number);
            //buildSettins
            this.fullPoll.pollDto.settings.forEach(s => this.settings[s.name] = s.value);
            this.refreshMenu();
            this.showStartPage();
        }
    }
</script>
